import React, { useState } from "react";

interface UserComponentProps {
  profile: string;
}

const ProfileForm = (props: UserComponentProps): JSX.Element => {
  const {
    profile
  } = props;

  const [profileCount, setProfileCount] = useState(profile.length);

  const onProfileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProfileCount(event.target.value.length);
  }

  const profilePlaceholder =
    `1973年埼玉県生まれ。あゆわら株式会社代表取締役。子どものころから美しい風景を眺めるのが好き。\n\n` +
    `新婚旅行で撮影したタヒチボラボラ島の写真を友人にほめられたことをきっかけに、額装してフリーマーケットで販売。\n\n` +
    `用意した作品が、すぐに完売したことに驚きネットショップを開設。\n\n` +
    `それ以降、定期的に作品を発表している。`

  return <React.Fragment>
    <textarea maxLength={500} className="textarea input-item"
              name="user[profile]" id="user_profile" defaultValue={profile}
              onChange={onProfileChange} placeholder={profilePlaceholder}
    />
    <div><span>{profileCount}/500</span></div>
  </React.Fragment>
}

export default ProfileForm
