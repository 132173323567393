import React, { useState, useEffect } from "react";
import { useWindowSize } from 'react-use';
import FrameWhiteHorizontal from "frames/frame_white_horizontal.jpg";
import FrameWhiteVertical from "frames/frame_white_vertical.jpg";
import FrameNaturalHorizontal from "frames/frame_natural_horizontal.jpg";
import FrameNaturalVertical from "frames/frame_natural_vertical.jpg";
import FrameBrownHorizontal from "frames/frame_brown_horizontal.jpg";
import FrameBrownVertical from "frames/frame_brown_vertical.jpg";
import FrameBlackHorizontal from "frames/frame_black_horizontal.jpg";
import FrameBlackVertical from "frames/frame_black_vertical.jpg";

interface ArtComponentProps {
  name: string | null;
  keyword: string | null;
  title: string | null;
  description: string;
  frameType: string | null;
  originalImageSrc: string | null;
  originalImageCache: string | null;
  uneditable: boolean;
}

const Form = (props: ArtComponentProps): JSX.Element => {
  const {
    name,
    keyword,
    title,
    description,
    originalImageSrc,
    uneditable
  } = props;

  const [frameType, setFrameType] = useState(props.frameType);
  const [frameImage, setFrameImage] = useState(FrameWhiteHorizontal);
  const [isFrameVertical, setIsFrameVertical] = useState(false);
  const [imageData, setImageData] = useState(originalImageSrc);
  const [frameWidth, setFrameWidth] = useState(isFrameVertical ? 398.6 : 544.5);
  const [frameHeight, setFrameHeight] = useState(isFrameVertical ? 544.5 : 398.6);
  const [naturalImageWidth, setNaturalImageWidth] = useState(undefined);
  const [naturalImageHeight, setNaturalImageHeight] = useState(undefined);
  const [imageWidth, setImageWidth] = useState(isFrameVertical ? 280 : 425.3);
  const [imageHeight, setImageHeight] = useState(isFrameVertical ? 425.3 : 280);
  const [descriptionCount, setDescriptionCount] = useState(description.length);
  const [originalImageChanged, setOriginalImageChanged] = useState(false);
  const [originalImageCache, setOriginalImageCache] = useState(props.originalImageCache);

  const descriptionPlaceholder =
    `広がる若い緑、小高い丘。やさしく落ちる木もれ日。その中を駆け回るちびっ子。\n` +
    `このちびっ子、実は次男です。\n` +
    `\n` +
    `次男が5歳のころ「こうえんにつれていって」と言われ、近所の公園に連れて行きました。\n` +
    `\n` +
    `公園の中を駆け回る息子をぼーっと見つめていたら、「あぁ、きれいだなぁ」と撮った一枚。\n` +
    `\n` +
    `この写真を眺めると、子どもが小さかったころ、よく公園に連れて行っていたことを思い出します。`

  // ブラウザの横幅をリアルタイムで取得
  const { width } = useWindowSize();

  const figureStyle = {
    position: "relative",
    width: `${frameWidth}px`,
    height: `${frameHeight}px`,
    margin: "10px 20px"
  }
    
  const frameStyle = {
    width: `${frameWidth}px`,
    height: `${frameHeight}px`
  }
  
  const uploadImageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: `${imageWidth}px`,
    height: `${imageHeight}px`
  }

  const onFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (!files.length) return;

    const image = new Image();
    image.onload = function () {
      setImageData(image.src);
      setNaturalImageWidth(image.naturalWidth);
      setNaturalImageHeight(image.naturalHeight);
    }
    image.src = URL.createObjectURL(files[0]);

    setOriginalImageChanged(true);
    setOriginalImageCache("");
  }

  const calcFrameStyle = (isVertical: boolean, windowWidth: number) => {
    let fWidth = isVertical ? 398.6 : 544.5;
    let fHeight = isVertical ? 544.5 : 398.6;
    if (windowWidth < 1200) {
      fWidth = fWidth * windowWidth / 1200;
      fHeight = fHeight * windowWidth / 1200;
    }
    setFrameWidth(fWidth);
    setFrameHeight(fHeight);
  }

  const calcUploadImageStyle = (naturalWidth: number, naturalHeight: number, isVertical: boolean, windowWidth: number) => {
    const resizeScale = getResizeScale(naturalWidth, naturalHeight, isVertical);
    let iWidth = naturalWidth * resizeScale;
    let iHeight = naturalHeight * resizeScale;
    if (windowWidth < 1200) {
      iWidth = iWidth * windowWidth / 1200;
      iHeight = iHeight * windowWidth / 1200;
    }
    setImageWidth(iWidth);
    setImageHeight(iHeight);
  }

  const getResizeScale = (naturalWidth: number, naturalHeight: number, isVertical: boolean): number => {
    const maxWidth = isVertical ? 280 : 425.3;
    const maxHeight = isVertical ? 425.3 : 280;
    const widthResizeScale = maxWidth / naturalWidth;
    const heightResizeScale = maxHeight / naturalHeight;
    return Math.min(widthResizeScale, heightResizeScale);
  }

  const changeFrame = (frameType: string) => {
    if (frameType === "white_horizontal") {
      setFrameImage(FrameWhiteHorizontal);
    } else if (frameType === "white_vertical") {
      setFrameImage(FrameWhiteVertical);
    } else if (frameType === "natural_horizontal") {
      setFrameImage(FrameNaturalHorizontal);
    } else if (frameType === "natural_vertical") {
      setFrameImage(FrameNaturalVertical);
    } else if (frameType === "brown_horizontal") {
      setFrameImage(FrameBrownHorizontal);
    } else if (frameType === "brown_vertical") {
      setFrameImage(FrameBrownVertical);
    } else if (frameType === "black_horizontal") {
      setFrameImage(FrameBlackHorizontal);
    } else if (frameType === "black_vertical") {
      setFrameImage(FrameBlackVertical);
    }
  }

  const onFrameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFrameType(event.target.value);
  }

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescriptionCount(event.target.value.length);
  }

  useEffect(() => {
    // 以下のように書けなかったので、|| を利用して書いている
    //const isVertical = frameType in ["white_vertical", "natural_vertical", "brown_vertical", "black_vertical"];
    const isVertical = frameType == "white_vertical" || frameType == "natural_vertical" ||
                       frameType == "brown_vertical" || frameType == "black_vertical";
    changeFrame(frameType);
    setIsFrameVertical(isVertical);
  }, [frameType]);

  useEffect(() => {
    const image = new Image() ;
    image.onload = function () {
      setImageData(image.src);
      setNaturalImageWidth(image.naturalWidth);
      setNaturalImageHeight(image.naturalHeight);
    }

    image.src = originalImageSrc ;
  }, [originalImageSrc]);

  useEffect(() => {
    // ブラウザの横幅に応じて画像の横幅を変更
    calcFrameStyle(isFrameVertical, width);
    calcUploadImageStyle(naturalImageWidth, naturalImageHeight, isFrameVertical, width);
  }, [width, naturalImageWidth, naturalImageHeight, isFrameVertical]);

  return <React.Fragment>
    {uneditable ? (
      <p>あゆわら承認後は、各項目の変更は不可です。</p>
    ) : (
      <>
        <p><span className='required'>*</span>は必須項目です。</p>
        <p>あゆわら承認前であれば、各項目を変更可能です。</p>
      </>
    )}
    <table border="1" className="art-table">
      <tbody>
        <tr>
          <td>作品名<span className="required">*</span></td>
          <td>
            <input maxLength={250} size={50} type="text" name="art[name]" id="art_name"
                   className="input-item" defaultValue={name} disabled={uneditable}
                   placeholder="版画 春を駆ける(縦)" />
          </td>
        </tr>
        <tr>
          <td>
            キーワード<span className="required">*</span><br />
            <span className="keyword-description">※複数の場合は、半角スペース or 全角スペース or 改行区切りで入力して下さい</span>
          </td>
          <td>
            <textarea maxLength={250} className="input-item textarea" name="art[keyword]" id="art_keyword"
                      defaultValue={keyword} disabled={uneditable} placeholder="緑 版画 春 新緑 子ども 埼玉県" />
          </td>
        </tr>
        <tr>
          <td>作品見出し<span className="required">*</span></td>
          <td>
            <input maxLength={250} size={50} type="text" name="art[title]" id="art_title" className="input-item"
                   defaultValue={title} disabled={uneditable} placeholder="木もれ日と子ども。" />
          </td>
        </tr>
        <tr>
          <td>作品説明<span className="required">*</span></td>
          <td className="description-column">
            <textarea maxLength={500} className="input-item textarea description"
                      name="art[description]" id="art_description" defaultValue={description}
                      disabled={uneditable} placeholder={descriptionPlaceholder} onChange={onDescriptionChange}
            />
            <div><span>{descriptionCount}/500</span></div>
          </td>
        </tr>
        {uneditable || (
          <tr>
            <td>
              画像(印刷用) ※最大10MB<span className="required">*</span><br />
              <span className="image-description">
                形式は jpg / jpeg<br />
                3600ピクセル * 2600ピクセル<br />
                または、<br />
                2600ピクセル * 3600ピクセル<br />
                以上のアップロード推奨
              </span>
            </td>
            <td>
              <div className="image-upload-block">
                <label className="image-upload-label">
                  <input
                    onChange={onFileChange}
                    type="file"
                    accept="image/jpg,image/jpeg"
                    name="art[original_image]"
                    id="art_original_image"
                    className="input-item image-upload-input"
                  />
                  ファイルを選択
                </label>
                <input
                  autocomplete="off"
                  type="hidden"
                  name="art[original_image_cache]"
                  id="art_original_image_cache"
                  value={originalImageCache}
                />
                {!!imageData || (
                  <p className="image-not-selected">未選択</p>
                )}
              </div>
            </td>
          </tr>
        )}
        <tr>
          <td>表示用画像フレーム<span className="required">*</span></td>
          <td>
            <select onChange={onFrameChange} name="art[frame_type]" id="art_frame_type"
                    defaultValue={frameType} disabled={uneditable}>
              <option value="white_horizontal">ホワイト横</option>
              <option value="white_vertical">ホワイト縦</option>
              <option value="natural_horizontal">ナチュラル横</option>
              <option value="natural_vertical">ナチュラル縦</option>
              <option value="brown_horizontal">ブラウン横</option>
              <option value="brown_vertical">ブラウン縦</option>
              <option value="black_horizontal">ブラック横</option>
              <option value="black_vertical">ブラック縦</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>画像</td>
          <td>
            {imageData && (
              <figure style={figureStyle}>
                <img style={frameStyle} src={frameImage} />
                <img style={uploadImageStyle} src={imageData} />
              </figure>
            )}
          </td>
        </tr>
      </tbody>
    </table>
    <input type="hidden" name="art[original_image_changed]" id="art_original_image_changed"
           value={originalImageChanged.toString()} />
  </React.Fragment>
}

export default Form
