import React, { useState, useEffect } from "react";

interface UserComponentProps {
  userImageSrc: string | null;
  userImageCache: string | null;
}

const uploadImageStyle = {
  width: "200px",
  height: "auto"
}

const UserImageForm = (props: UserComponentProps): JSX.Element => {
  const {
    userImageSrc
  } = props;

  const [imageData, setImageData] = useState(userImageSrc);
  const [userImageChanged, setUserImageChanged] = useState(false);
  const [userImageCache, setUserImageCache] = useState(props.userImageCache);

  const onFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (!files.length) return;

    const image = new Image();
    image.onload = function () {
      setImageData(image.src);
    }
    image.src = URL.createObjectURL(files[0]);

    setUserImageChanged(true);
    setUserImageCache("");
  }

  useEffect(() => {
    const image = new Image() ;
    image.onload = function () {
      setImageData(image.src);
    }

    image.src = userImageSrc ;
  }, [userImageSrc]);

  return <React.Fragment>
    <div className="user-image-upload-block">
      <label className="user-image-upload-label">
        <input
          onChange={onFileChange}
          type="file"
          accept="image/jpg,image/jpeg"
          name="user[user_image]"
          id="user_user_image"
          className="input-item user-image-upload-input"
        />
        ファイルを選択
      </label>
      <input
        autocomplete="off"
        type="hidden"
        name="user[user_image_cache]"
        id="user_user_image_cache"
        value={userImageCache}
      />
      {!!imageData || (
        <p className="user-image-not-selected">未選択</p>
      )}
    </div>
    {imageData && (
      <div>
        <img style={uploadImageStyle} src={imageData} />
      </div>
    )}
    <input type="hidden" name="user[user_image_changed]" id="user_user_image_changed"
           value={userImageChanged.toString()} />
  </React.Fragment>
}

export default UserImageForm
