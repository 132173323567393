import React, { useState, useEffect } from "react";

interface UserComponentProps {
  proofImageSrc: string | null;
  proofImageCache: string | null;
}

const uploadImageStyle = {
  width: "200px",
  height: "auto"
}

const ProofImageForm = (props: UserComponentProps): JSX.Element => {
  const {
    proofImageSrc
  } = props;

  const [imageData, setImageData] = useState(proofImageSrc);
  const [proofImageWidth, setProofImageWidth] = useState(undefined);
  const [proofImageHeight, setProofImageHeight] = useState(undefined);
  const [proofImageChanged, setProofImageChanged] = useState(false);
  const [proofImageCache, setProofImageCache] = useState(props.proofImageCache);

  useEffect(() => {
    const image = new Image() ;
    image.onload = function () {
      setImageData(image.src);
      setProofImageWidth(image.naturalWidth);
      setProofImageHeight(image.naturalHeight);
    }

    image.src = proofImageSrc ;
  }, [proofImageSrc]);


  const onFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (!files.length) return;

    const image = new Image();
    image.onload = function () {
      setImageData(image.src);
      setProofImageWidth(image.naturalWidth);
      setProofImageHeight(image.naturalHeight);
    }
    image.src = URL.createObjectURL(files[0]);

    setProofImageChanged(true);
    setProofImageCache("");
  }

  return <React.Fragment>
    <div className="proof-image-upload-block">
      <label className="proof-image-upload-label">
        <input
          onChange={onFileChange}
          type="file" 
          accept="image/jpg,image/jpeg,image/png"
          name="user[proof_image]"
          id="user_proof_image"
          className="input-item user-image-upload-input"
        />
        ファイルを選択
      </label>
      <input
        autocomplete="off"
        type="hidden"
        name="user[proof_image_cache]"
        id="user_proof_image_cache"
        value={proofImageCache}
      />
      {!!imageData || (
        <p className="proof-image-not-selected">未選択</p>
      )}
    </div>
    {imageData && (
      <div>
        <img style={uploadImageStyle} src={imageData} />
      </div>
    )}
    <input type="hidden" name="user[proof_image_width]" id="user_proof_image_width" value={proofImageWidth} />
    <input type="hidden" name="user[proof_image_height]" id="user_proof_image_height" value={proofImageHeight} />
    <input type="hidden" name="user[proof_image_changed]" id="user_proof_image_changed"
           value={proofImageChanged.toString()} />
  </React.Fragment>
}

export default ProofImageForm
